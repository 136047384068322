<template>
  <div class="website-wrapper">
    <div class="website-header">
      <div class="main-title">Websites</div>
      <el-button type="primary" round @click="addWebsiteDialogVisible = true">Add new website</el-button>
    </div>
    <div class="website-body">
      <el-table :data="websites" v-loading="isLoading">
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="title" label="Title"></el-table-column>
        <el-table-column prop="url" label="Url"></el-table-column>
        <el-table-column prop="isScraping" label="Scraping" width="100" align="center">
          <template slot-scope="scope">
            <el-icon v-if="scope.row.isScraping" name="check"></el-icon>
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="Tags">
          <template slot-scope="scope">
            <div style="margin-bottom: 15px" v-for="(tags, name) in scope.row.tags" :key="name">
              <span>{{ name }} : </span>
              <el-tag v-for="(tag, index) in tags" :key="index" style="margin-right: 10px">{{
        tag
      }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <el-button v-if="scope.row.content?.length > 0" icon="el-icon-upload" @click="handleUploadWebsite(scope.row)" circle type="primary"></el-button>
            <el-button v-if="scope.row.isUploaded" type="primary" size="small" style="margin-right: 10px"
              @click="openStatusDialog(item.document_id)" :loading="isFetchDocumentStatus"
              :disabled="isFetchDocumentStatus">STATUS</el-button>
            <el-button icon="el-icon-edit" @click="handleEditWebsite(scope.row)" circle type="warning"></el-button>
            <!-- <el-button icon="el-icon-plus" circle type="primary"></el-button> -->
            <el-button icon="el-icon-delete" circle type="danger"
              @click="handleDeleteWebsite(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="website-footer" v-if="websitesLength > 0">
      <el-pagination background layout="prev, pager, next" :total="websitesLength" :page-size="5" :pager-count="5"
        @current-change="handleCurrentPageChange"></el-pagination>
    </div>
    <Editor v-if="editorVisible" :website="selectedWebsite" :editorVisible="editorVisible"
      @updateEditorVisible="updateEditorVisible" @handleUpdatedWebsite="handleUpdatedWebsite"
      :isEditorDialogLoading="isEditorDialogLoading" />
    <AddWebsite v-if="addWebsiteDialogVisible" :addWebsiteDialogVisible="addWebsiteDialogVisible"
      @updateEditorVisible="updateEditorVisible" @handleCreateWebsite="handleCreateWebsite"
      :isWebsiteDialogLoading="isWebsiteDialogLoading" />
    <DocumentStatusModal v-if="statusDialogVisible" :statusDialogVisible="statusDialogVisible"
      @updateStatusDialogVisible="updateStatusDialogVisible" @refreshDocumentStatus="refreshDocumentStatus"
      :selectedDocumentStatus="selectedDocumentStatus" :isRefreshingStatus="isRefreshingStatus" />
  </div>
</template>

<script>
import Editor from "./Editor.vue";
import AddWebsite from "./AddWebsite.vue";
import DocumentStatusModal from "../Documents/DocumentStatusModal.vue"

export default {
  components: {
    Editor,
    AddWebsite,
    DocumentStatusModal
  },
  data() {
    return {
      editorVisible: false,
      addWebsiteDialogVisible: false,
      selectedWebsite: {},
      websitesLength: 0,
      isWebsiteDialogLoading: false,
      isEditorDialogLoading: false,
      isLoading: false,
      selectedDocumentStatus: null,
      isFetchDocumentStatus: false,
      isRefreshingStatus: false,
      statusDialogVisible: false
    };
  },
  computed: {
    websites() {
      const websites = _.cloneDeep(this.$store.state.genai.genai_websites);
      websites.forEach((website) => {
        if (website.tags) {
          website.tags = JSON.parse(website.tags);
        } else {
          website.tags = {};
        }
      });
      return websites;
    },
  },
  methods: {
    handleEditWebsite(website) {
      this.selectedWebsite = website;
      this.editorVisible = true;
    },
    updateEditorVisible({ dialog, status }) {
      switch (dialog) {
        case "editor":
          this.editorVisible = status;
          break;
        case "addWebsite":
          this.addWebsiteDialogVisible = status;
          break;
        default:
          break;
      }
    },
    async handleCreateWebsite(website) {
      this.isWebsiteDialogLoading = true;
      const result = await this.$store.dispatch("GENAI_CREATE_WEBSITE", website);
      this.isWebsiteDialogLoading = false;
      this.addWebsiteDialogVisible = false;
      if (result.success) {
        this.$message({
          type: "success",
          message: "Create website successfully!",
        });
      } else {
        this.$message({
          type: "error",
          message: "Failed to create website",
        });
      }
    },
    async handleUploadWebsite(id) {
      this.$confirm("Are you sure to upload this website?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        this.isLoading = true;
        const result = await this.$store.dispatch("GENAI_UPLOAD_WEBSITE", { id });
        if (result.success) {
          this.$message({
            type: "success",
            message: "Upload successfully!",
          });
        }
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        console.log(error);
      });
    },
    handleDeleteWebsite(id) {
      this.$confirm("Are you sure to delete this website?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.isLoading = true;
          const result = await this.$store.dispatch("GENAI_DELETE_WEBSITE", { id });
          if (result.success) {
            this.$message({
              type: "success",
              message: "Delete successfully!",
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async handleUpdatedWebsite(newWebsite) {
      this.isLoading = true;
      this.isEditorDialogLoading = true;
      const result = await this.$store.dispatch("GENAI_UPDATE_WEBSITE", newWebsite);
      if (result.success) {
        this.isEditorDialogLoading = false;
        this.editorVisible = false;
        this.isLoading = false;
        this.$message({
          type: "success",
          message: "Update successfully!",
        });
      } else {
        this.isLoading = false;
        this.isEditorDialogLoading = false;
        this.$message({
          type: "error",
          message: "Failed to update website",
        });
      }
    },
    async handleCurrentPageChange(page) {
      this.isLoading = true;
      await this.$store.dispatch("GENAI_GET_WEBSITES", { page });
      this.isLoading = false;
    },
    updateStatusDialogVisible(value) {
      this.statusDialogVisible = value;
    },
    async openStatusDialog(documentId) {
      try {
        this.isLoading = true;
        const documentStatus = await this.$store.dispatch("GENAI_GET_DOCUMENT_STATUS", {
          documentId,
        });
        this.selectedDocumentStatus = documentStatus;
        this.statusDialogVisible = true;
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "There was an error getting the document status",
          type: "error",
        });
        this.isLoading = false;
      }
    },
    async refreshDocumentStatus(documentId) {
      this.isRefreshingStatus = true;
      const documentStatus = await this.$store.dispatch("GENAI_GET_DOCUMENT_STATUS", {
        documentId,
      });
      this.selectedDocumentStatus = documentStatus;
      this.isRefreshingStatus = false;
      return documentStatus;
    },
  },
  async created() {
    this.isLoading = true;
    const websites = await this.$store.dispatch("GENAI_GET_WEBSITES", { page: 1 });
    this.websitesLength = websites.count;
    this.isLoading = false;
  },
};
</script>

<style>
.website-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.website-body {
  padding: 20px;
}

.website-wrapper {
  margin: 20px;
}
</style>
